<template>
  <b-navbar style="flex-direction:column;" toggleable="lg" type="dark" variant="dark" sticky>
    <b-container>
      <b-navbar-brand class="navbar">
        <img src="../assets/imgs/logo.png" alt="Viteapp Logo" class="vite-logo clickable" @click="gotoHome" />
        <div id="google_translate_element" class="language-select"></div>
      </b-navbar-brand>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
      <!-- <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/?section=1" class="pt-2">Ride</b-nav-item>
          <b-nav-item href="/?section=2" class="pt-2">Delivery</b-nav-item>
          <b-nav-item href="/business" class="pt-2">Vite for business</b-nav-item>
          <b-nav-item href="https://wa.me/23059150708" class="pt-2">
            +(230) 59150708
          </b-nav-item>
          <b-nav-item href="/driver" v-if="currentRouteName != 'Driver'">
            <b-button size="sm" pill variant="primary" class="px-4 py-2" name="become-driver">
              Become a Driver
            </b-button>
          </b-nav-item>

          <b-nav-item href="https://driver.viteapp.co" v-else>
            <b-button size="sm" pill variant="primary" class="px-4 py-2" name="login">
              Login
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse> -->
    </b-container>
  </b-navbar>
</template>
<script>
export default {
  name: "Navbar",
  data() {
    return {
      currentRouteName: "",
    };
  },
  methods: {
    // gotoDriver() {
    //   window.location("https://driver.viteapp.co/");
    // },
    gotoHome() {
      // redirect to viteapp.co
      window.location.href = "https://viteapp.co";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    this.currentRouteName = this.$route.name;
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  width: 100%;
  /* height: 50px; */
  align-items: center;
  justify-content: space-between;
}

.vite-logo {
  width: 75px;
  z-index: 101;
}

/* .language-select {
  position: absolute;
  top: 15px;
  left: 17%; */
/* position:absolute; */
/* } */

/* .brand-top{

} */
</style>
